import { useBreak } from "hooks/useBreak"
import { Label } from "./Overview.label"
import { _bootstrap } from "state/store.bootstrap"
import { getFocus, useAtom, useGet } from "state/jotai"
import { current_language, rotate_by_slider } from "state/store.global"
import { AnimatePresence } from "framer-motion"
import { motion } from 'framer-motion'
import { MobileLabel } from "./MobileOverview.label"
import { _project } from "state/store.projects"

export const Overview = ({ index, rmode, status, showLabel, setShowLabel, hoveredId }) => {

   
    const labelOptions = getFocus(_bootstrap, 'projectnames')
   
    const currentLanguage = useAtom(current_language)[0];
  
    const isRotate_Done = useGet(rotate_by_slider)
    const isDesktop = useBreak('md_up')
    
    const L = [ // desktop labels position
        {L1: [1320, 250] , L2: [810, 220] },
        { L1: [1300, 270] , L2: [1200, 80] },
        { L1: [400, 340] , L2: [1300, 350] },
        {  L1: [890, 50] , L2: [700, 270] },
        {   L1: [1320, 250] ,L2: [790, 260] },
    ];

    const circle = [ // desktop circle position
        { L1: [50, 300]  , L2: [50, 300]  },
        {  L1: [40, 300] ,  L2: [40, 300] },
        { L1: [40, 300] , L2: [40, 300] },
        { L1: [40, 300] ,L2: [40, 300] },
        { L1: [50, 300], L2: [50, 300] },
    ]
    const mobileL = [ // mobile labels position
        { L1: [1300, 200] , L2: [850, 230] },
        { L1: [1200, 250] , L2: [1100, 50] },
        { L1: [550, 270] , L2: [1300, 300] },
        { L1: [900, 50]  ,L2: [810, 220]  },
        { L1: [1300, 200] ,L2: [780, 230] },
    ];

    const mobileLine = [ // mobile line lengths
        { L2: [450, 0] },
        { L2: [400, 0] },
        { L2: [550, 0] },
        { L2: [500, 0] },
        { L2: [450, 0] },
    ];

    const mobileLineAngle = [ // mobile line angles
        { L2: 215 },
        { L2: 285 },
        { L2: 85 },
        { L2: -285 },
        { L2: 215 },
    ];

    const mobileLabelAxis = [ // mobile label positions
        { L1:[50, 0] , L2:[50, 0] },
        { L1:[50, 0] , L2:[50, 0] },
        { L1:[50, 0] , L2:[50, 0] },
        { L1:[50, 0] , L2:[50, 0] },
        { L1:[50, 0] , L2:[50, 0] },
    ];

    const animation = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.1, ease: 'linear' },
    }

    return (
        <AnimatePresence>
            {
                status === 'DONE' && isRotate_Done &&
                <>
                    {
                        
                        isDesktop ?
                        labelOptions?.[currentLanguage]?.map((label, i) => {
                            if (L?.[index]?.[`L${i}`]) {
                               
                                if (i) {
                                    return (
                                        <motion.div {...animation} key={i}>
                                            <Label setShowLabel={setShowLabel} c={L?.[index]?.[`L${i}`]} sc={1} d={40} l={0} r={circle?.[index]?.[`L${i}`]} {...{ rmode }} labelsInfo={label} showLabel={showLabel} hoveredId={hoveredId}/>
                                        </motion.div>
                                    )
                                }
                                return null;
                                }
                            })
                            :
                            labelOptions?.[currentLanguage]?.map((label, i) => {
                              
                                if (i) {
                                    return (
                                        <motion.div {...animation} key={i}>
                                            <MobileLabel setShowLabel={setShowLabel} c={mobileL?.[index]?.[`L${i}`]} sc={1} d={40} l={0} r={circle?.[index]?.[`L${i}`]} {...{ rmode }} labelsInfo={label} line={mobileLine?.[index]?.[`L${i}`]} angle={mobileLineAngle?.[index]?.[`L${i}`]} mobileLabelAxis={mobileLabelAxis?.[index]?.[`L${i}`]} showLabel={showLabel} hoveredId={hoveredId}/>
                                        </motion.div>
                                    )
                                }
                                return null;
                              
                            })
                    }
                </>
            }
        </AnimatePresence>
    )
}
