import styled, { css } from 'styled-components/macro'
import { sb } from 'styles/styles.scrollbar'
import { MENU } from 'constants'

export const Menu = styled.div`
  width: ${ MENU.DESKTOP_NAV_DRAWER_W }px;
  border-radius: 0 0 6px 6px;
  background-color: ${({ clr }) => `${clr?.primary}e6`};
  overflow: scroll;
  ${sb.hide};
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  position:fixed;
  top:72px;
  z-index: 10;
  right: 0;
`

export const Tile = styled.div`
  display: flex;
  cursor: pointer;
  color: ${({ clr }) => clr?.primary_text};
  align-items: center;
  min-width: 0;
  gap: 8px;
  padding:16px 0;
  // padding-left: 20px;
  // padding-top: 5px;
  // padding-bottom: 5px;
  /* border:5px solid red; */
`

export const Split = styled.div`
  // margin: 0 auto;
  height: 1px;
  background-color: ${({ clr }) => `${clr?.icons}`};
  width: calc(100%);
`

export const Text = styled.div`
  padding-right: 60px;
  white-space: nowrap;
  color : ${({ clr }) => `${clr?.primary_text}`};
  /* border: 4px solid pink; */
`