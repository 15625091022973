import styled, { css } from 'styled-components/macro'
import { sb } from 'styles/styles.scrollbar'
import { BREAK_ } from 'constants'

export const Bottom = styled.div`
  height: 101px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  bottom: 0;
  border-top: 1px solid ${({ clr ,scroll}) => scroll ? clr.primary : "none"};
`
export const Head = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 12px;
  align-self: center;
  border-bottom: 1px solid ${({clr}) => `${clr.primary_text}` };
  ${ BREAK_.md_dn } {
    top: 20px;
    right: 10px;
  }
`
export const Scroll = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({clr}) => clr?.primary };
  ${ sb.hide };
  font-size:1rem;
  overflow: auto;
  padding-top: 24px;
`

export const Count = styled.div`
  color: ${({clr}) => clr?.primary_text};
  width: 100%;
  text-align: center;
`
export const Split = styled.div`
  height: 1px;
  background-color: ${({ clr }) => `${clr.icons}40`};
  width: 100%;
`

export const FilterOptions = styled.div`
  width: 100%;
  overflow: auto;
  height: ${({ matches, isMobile, isFilterVisible }) => ( matches ? isMobile ? "calc(100% - 280px)" : !isFilterVisible ? "calc(100vh - 342px)" : "289px" : !isFilterVisible ? "calc(100vh - 342px)" : "553px" )};
`;
export const FilterBottom = styled.div`
  width: 100%;
  height: 101px;
`;