import styled from 'styled-components/macro'
import { active_project, static_assets } from 'state/store.global'
import { useAtomValue, useGet } from 'state/jotai'
import { BREAK_ } from 'constants'

export const Logo = ({ link = null, device , style }) => {
  const assets = useAtomValue(static_assets)
  const obj = assets[`logo_${device}`]

  // const { pin, pid }  = useGet(active_project);
  // const menus = getFocus(_project, "menuItems")?.[pin]?.[pid] ?? [];

  return (
    <>
      <A href={link} style={{style}}>
        <Wrap device={device} >
          <Img src={obj.src} alt='' device={device} />
        </Wrap>
      </A>
    </>
  )
}

export const Img = styled.img`
  display:block;
  width:100%;
  height: 100%;
`
export const Wrap = styled.div`
 display: inline-block;
  height: 100%;
  width: 120px;
  ${ BREAK_.md_dn } {
    height: auto;
    width: 96px;
  }
`
export const A = styled.a`
  width:100%;
  height:100%;
`