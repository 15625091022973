import CodeIcon from "@mui/icons-material/Code";
import {
  InputBase,
  InputLabel,
  RadioGroup,
  Select,
  Slider,
  SliderThumb,
  styled,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const CustomLabel = styled(InputLabel)(() => ({
  display: "block",
  position: "unset",
  color: "#000",
  // fontSize: 16,
  transform: "none",
  // fontWeight: 400,
  "& > span": {
    color: '#000',
  },
  "&.MuiInputLabel-root.Mui-focused": {
    color: '#000'
  },
  "&.MuiInputLabel-root.Mui-error": {
    color: '#000'
  }
}));

const CustomInput = styled(InputBase)(({ theme }) => ({
  // maxWidth: "300px",
  width: "300px",
  maxWidth: "100%",
  "label + &": {
    marginTop: theme.spacing(1),
  },
  color: "#000",
  "& .MuiInputBase-input": {
    borderRadius: 6,
    position: "relative",
    backgroundColor: "#ffffff",
    fontSize: 14,
    fontFamily:"Cerebri sans-light",
    width: "100%",
    padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
    transition: "border-color 0.3s",
    border: "1px solid #D1D1D1",
    "&:focus": {
      border: "1px solid #000",
    },
  },
  "&.Mui-error .MuiInputBase-input": {
    borderColor: "#D1D1D1",
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  color: "#000",
  "label + &": {
    marginTop: theme.spacing(1),
  },
  boxShadow: 'none', 
  
  "& .MuiInputBase-input": {
    borderRadius: 6,
    position: "relative",
    backgroundColor: "#ffffff",
    fontSize: 16,
    width: "100%",
    padding: `11px 16px`,
    height: "44px",
    transition: "border-color 0.3s",
  },

  ".MuiSelect-select:focus": {
    backgroundColor: 'white',
    borderRadius: 6,
  },

  // "input:not(:placeholder-shown) + .css-1e88mfs-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1e88mfs-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1e88mfs-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
  //   backgroundColor: 'green'
  // },
  "& .MuiSelect-icon": {
    right: '16px',
    display: "flex"
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: '1px solid #000',
  },


  MenuProps: {
    PaperProps: {
      sx: {
        bgcolor: 'blue',
        '& .MuiMenuItem-root': {
          padding: 2,
        },
      },
    },
  },
  "&.Mui-error .MuiInputBase-input": {
    borderColor: theme.palette.error.main,
  },
}));

const CustomRadioGroup = styled(RadioGroup)(() => ({
 "label > span" :  {color: "#000" }
}));

const CustomSlider = styled(Slider)(() => ({
  color: "#ffffff",
  height: 5,
  marginTop: "36px",
  padding: "15px 0",
  borderRadius: 6,
  "& .MuiSlider-thumb": {
    height: 40,
    width: 40,
    border: "3px solid #F3F3F3",
    "&:focus, &:hover, &.Mui-active": {},
    "& .MuiSvgIcon-root": {
      fill: "#000000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: "1",
  },
  "& .MuiSlider-mark": {
    display: "none",
  },
  "& .MuiSlider-markLabel": {
    color: "currentColor",
    marginTop: 5,
  },
  "& .MuiSlider-valueLabel": {
    backgroundColor: "currentColor",
  },
  "& .MuiSlider-valueLabel span": {
    color: "#000000",
  },
}));

const CustomSliderThumb = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <CodeIcon sx={{ width: 24 }} />
    </SliderThumb>
  );
};

CustomSliderThumb.propTypes = {
  children: PropTypes.node,
};


const PlaceHolderInputField = styled(TextField)(({ theme }) => ({
  // maxWidth: "300px",
  width: "300px",
  maxWidth: "100%",

  "& .MuiOutlinedInput-root": {
    borderRadius: 6,
  },
  "& .MuiInputBase-input": {
    borderRadius: 6,
    position: "relative",
    backgroundColor: "#FFF",
    width: "100%",
    padding: "11px 16px",
    transition: "border-color 0.3s",
    boxSizing: "border-box",
    height: "44px",
    fontSize: 14,
    fontFamily: "Cerebri sans-light !important",
  },

  "& legend": {
    fontSize: "10px",
  },
  
  "input": {
    "&::placeholder": {
      fontFamily: "Cerebri sans-light !important",
      fontSize: "14px !important",
      letterSpacing :"0",
      color: "#000 !important",  // or any other color you want
      opacity: 1,  // Ensures full opacity
    },
  },
  "& .MuiInputLabel-formControl": {
    color: "#000",
    fontFamily: "Cerebri sans-light !important",
    fontSize: "14px",
    letterSpacing :"0",
    // fontWeight: 500,
    transform: "translate(14px, 11px)",
  },
  "& .MuiInputLabel-formControl.Mui-focused": {
    color: "#000",
    fontSize: "10px",
    transform: "translate(14px, -7px)",
  },
  "& .MuiFormLabel-filled.MuiInputLabel-formControl": {
    color: "#000",
    fontSize: "10px",
    transform: "translate(14px, -7px)",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: 6,
    border: "1px solid #D1D1D1",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #000 !important",
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: '#D1D1D1',
    filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))"
  },
  // "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
  //   border: "1px solid #E33716 !important",
  // },

  "& .MuiFormHelperText-root": {
    marginTop: '0',
    marginLeft: '0'
  }
}));

export { CustomLabel, CustomInput, CustomSlider, CustomSelect, PlaceHolderInputField, CustomRadioGroup };
