const palette = {
  primary: {
    main: "#AB6628",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#41584A",
    contrastText: "#ffffff",
  },
  text: {
    primary: "#000000",
    
  },
  success: {
    main: "#0E912C",
  },
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
};

export default palette;
