import { useEffect, useState } from "react";
import {
  Svg,
  Circle,
  Group,
  Line,
  Pos,
  Lab,
  Outer,
  Text1,
  Text2,
  Path,
  CircleGroup,
  LabContainer,
} from "./Overview.label.style";

import { useLocation } from "react-router-dom";
import { useHover } from "hooks/useHover";
import { useAtom } from "jotai";
import { a_labHovered, active_project } from "state/store.global";
import { useClick } from "./Overview.label.click";
import { _project } from "state/store.projects";
import { getFocus, useGet } from "state/jotai";
import { useBreak } from "hooks/useBreak";
import { _bootstrap } from "state/store.bootstrap";

export const MobileLabel = ({
  c,
  sc,
  d,
  l,
  rmode,
  r,
  labelsInfo,
  line,
  angle,
  mobileLabelAxis,
  showLabel,
  setShowLabel,
  hoveredId,
}) => {
  // console.log(r,"rrrrrrrrr")
  // if (c === undefined || r === undefined ){
  //   return <></>;
  // }

  // if(mobileLabelAxis === undefined || angle === undefined || line === undefined) {
  //     return <></>
  // }

  // Continue with the rest of your code if the conditions are met
  // console.log(labelsInfo,"infooo")

  const { pin, pid } = useGet(active_project);
  const unitStatus = getFocus(_project, "unitStatuses")?.[pin]?.[pid] ?? [];
  const availableTranslation =
    unitStatus?.filter((u) => u.ID === 10)?.[0]?.Name ?? "";

  // data for labels
  const projectUnitsStatus = getFocus(_bootstrap, "projects");
  const options = getFocus(_project, "filteroptions");
  const PID = labelsInfo?.PID;
  const PIN = labelsInfo?.PIN;
  const unitsCounts = projectUnitsStatus?.filter((p) => p?.ProjectID === PID);
  const totalUnits =
    unitsCounts?.[0]?.NoOfRentalUnits === 0
      ? unitsCounts?.[0]?.NoOfSalesUnits
      : unitsCounts?.[0]?.NoOfRentalUnits;
  const vacantUnits = unitsCounts?.[0]?.NoOfVacantUnits;
  const reservedUnits = unitsCounts?.[0]?.NoOfReservedUnits;
  const rentedOut = unitsCounts?.[0]?.NoOfRentedOutUnits;
  const allUnits = getFocus(_project, "units");

  // units count through units "KP"
  // const totalUnits = allUnits?.[PIN]?.[PID]?.NumberOfUnits
  // const reservedUnits = (allUnits?.[PIN]?.[PID]?.Units?.filter((u) => u?.Data?.UnitStatusID?.Value == "20").length);
  // const soldUnits = (allUnits?.[PIN]?.[PID]?.Units?.filter((u) => u?.Data?.UnitStatusID?.Value == "40").length);

  const roomSize = options[PIN]?.[PID]?.filter(
    (p) => p?.Label == "Størrelse"
  )?.[0];
  const noOfRooms = options[PIN]?.[PID]?.filter(
    (p) => p?.Label == "Værelser"
  )?.[0];
  // gets move in date from all units
  const moveInDate = allUnits?.[PIN]?.[PID]?.Units?.map(
    (u) => u?.Data?.Indflytningsdato?.UnformattedValue
  );
  // Filter out undefined values
  const validDates = moveInDate?.filter((date) => date);
  // Store all dates in an array
  const datesArray = validDates?.map((date) => ({ date }));

  const hardcodedMoveInDate =
    allUnits?.[PIN]?.[PID]?.Units?.[0]?.Data?.Indflytningsdatoproperty
      ?.UnformattedValue;

  const here = useLocation().pathname.split("/");

  // const setLabHovered = useAtom(a_labHovered)[1];
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const isOverview = here[here.length - 1] == "isometry";

  // const [hoverRef, isHovered] = useHover();
  const click = useClick();

  const s = 0.6 / sc === Infinity ? 10 : 0.6 / sc;
  const move = (cx, cy, dir = "-") => ({
    transform: `rotate(${dir}${d} ${cx} ${cy})`,
  });
  const g = { ...move(50, 50, "+") };
  const svg = { viewBox: "0 0 100 100" };
  const circ1 = { cx: 0, cy: 0, r: 15, clr: clr };
  const circ2 = { cx: 0, cy: 0, r: 25, fillOpacity: "0.5", clr: clr };
  const circ3 = { cx: 0, cy: 0, r: 35, fillOpacity: "0.3", clr: clr };
  const cx = r?.[0];
  const cy = r?.[1];
  const circleGroup = { transform: `translate(${cx} ${cy})` };
  const path1 = { d: `M ${cx} ${cy} L 50 ${-l + 50}`, clr: clr };
  const [notHovered, setNotHovered] = useState(true);

  const dl = { d, l, s, notHovered };

  useEffect(() => {
    if (hoveredId == labelsInfo?.Presentation) {
      setNotHovered(false);
    } else {
      setNotHovered(true);
    }
  }, [hoveredId]);

  if (isOverview && !rmode)
    return (
      <>
        <Pos c={c}>
          <Svg {...svg}>
            <CircleGroup {...circleGroup}>
              <Circle {...circ1}></Circle>
              <Circle {...circ2}></Circle>
              <Circle {...circ3}></Circle>
            </CircleGroup>
          </Svg>
  
          {/* Commented-out section */}
          {/* 
          {hoveredId == labelsInfo?.Presentation ? (
            <Outer
              {...dl}
              xAxis={mobileLabelAxis?.[0]}
              yAxis={mobileLabelAxis?.[1]}
            >
              <LabContainer
                {...dl}
                clr={clr}
                onClick={null}
              >
                <Text2
                  style={{
                    borderBottom: "2px solid #fff",
                    padding: "8px 16px",
                  }}
                >
                  {labelsInfo?.Presentation == "c4"
                    ? buttonsLabel?.filter(
                        (btn) => btn.UILabelProperty === "Enhedertilleje"
                      )[0]?.Label
                    : "Boliger til leje"}
                </Text2>
                <Lab clr={clr}>
                  <Text1 s={s}>{labelsInfo?.Presentation}</Text1>
                  <Text2>
                    {totalUnits}&nbsp;
                    {totalUnits > 1
                      ? buttonsLabel
                          ?.filter(
                            (btn) => btn.UILabelProperty === "boliger"
                          )[0]
                          ?.Label?.toLowerCase()
                      : buttonsLabel
                          ?.filter((btn) => btn.UILabelProperty === "bolig")[0]
                          ?.Label?.toLowerCase()}
                  </Text2>
                  <Text2>
                    {labelsInfo?.available_Units}{" "}
                    {labelsInfo?.available_Units > 1
                      ? buttonsLabel?.filter(
                          (btn) => btn.UILabelProperty === "Ledige"
                        )[0]?.Label
                      : availableTranslation?.toLowerCase()}
                  </Text2>
                  <Text2>{`${roomSize?.MinValue} - ${roomSize?.MaxValue} ${roomSize?.Postfix}`}</Text2>
                  <Text2>
                    {`${noOfRooms?.MinValue} - ${noOfRooms?.MaxValue}`}{" "}
                    {noOfRooms?.MaxValue > 1
                      ? buttonsLabel?.filter(
                          (btn) => btn.UILabelProperty === "værelser"
                        )[0]?.Label
                      : buttonsLabel?.filter(
                          (btn) => btn.UILabelProperty === "værelse"
                        )[0]?.Label}
                  </Text2>
                  {hardcodedMoveInDate && (
                    <Text2>
                      {
                        buttonsLabel?.filter(
                          (btn) => btn.UILabelProperty === "Indflytning"
                        )[0]?.Label
                      }
                      : {hardcodedMoveInDate}
                    </Text2>
                  )}
                  <button
                    className="paragraph2-regular"
                    onClick={() => {
                      click(labelsInfo);
                      setShowLabel(false);
                    }}
                    style={{
                      padding: "4px 16px",
                      borderRadius: "6px",
                      border: "1px solid #D1D1D1",
                      backgroundColor: "#FFF",
                    }}
                  >
                    Vælg
                  </button>
                </Lab>
              </LabContainer>
            </Outer>
          ) : ( */}
            
          {/* Display only this part */}
          <Outer
            {...dl}
            xAxis={mobileLabelAxis?.[0]}
            yAxis={mobileLabelAxis?.[1]}
          >
            <LabContainer
              {...dl}
              clr={clr}
              onClick={null}
            >
              <Text2 style={{ padding: "8px 16px" }}>
                {labelsInfo?.Presentation == "c4"
                  ? buttonsLabel?.filter(
                      (btn) => btn.UILabelProperty === "Enhedertilleje"
                    )[0]?.Label
                  : "Boliger til leje"}
              </Text2>
            </LabContainer>
          </Outer>
        </Pos>
      </>
    );
};

const style = {
  zIndex: 1000,
  background: "black",
  color: "white",
  height: 100,
  width: 220,
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.6rem",
};
