import { Button, IconButton } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { getFocus, useAtom, useGet } from "state/jotai";
import { active_project, share_content, vis_share } from "state/store.global";
import { _project } from "state/store.projects";

function ButtonShare({ unitId, iconOnly, label, address, highlights, rent  }) {
  const setShare = useAtom(vis_share)[1];
  const shareContent = useAtom(share_content)[1];

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      setShare(true);
      shareContent({
        unitId,
        address,
        highlights,
        rent
      });
    },
    [unitId]
  );

  return (
    <>
      {iconOnly ? (
        <IconButton
          onClick={handleClick}
          
         
        >
          <Icon icon="share" isFaIcon={true} className="icon-badge" />
        </IconButton>
      ) : (
        <Button
          type="button"
          onClick={handleClick}
          color="inherit"
          startIcon={<Icon icon="share" isFaIcon={true} className="icon-badge"/>}
          disableRipple
          disableFocusRipple
          sx={{
            padding: 0,
            minWidth: 0,
            background: "none",
            boxShadow: "none",
            transition:
              "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            "&:hover": {
              background: "none",
              boxShadow: "none",
            },
           
          }}
        >
          <span className="paragraph1-regular">
            {
              buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "Del"
              )[0]?.Label
            }
          </span>
        </Button>
      )}
    </>
  );
}

ButtonShare.propTypes = {
  unitId: PropTypes.number.isRequired,
  iconOnly: PropTypes.bool,
};

export default ButtonShare;
