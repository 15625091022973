import Select from 'react-select'
import { PlaceHolderInputField, styles } from './Dropdown.Style'
import { Controller } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomMenuList, CustomValueContainer, DropdownIndicator, Option } from './CustomMenulist';
import { useEffect, useRef, useState } from 'react';
import { HelperText } from 'components/FormComponent/TextField';

export const SearchDropdown = ({ searchable, placeholder, description, options=[], control, isCheckBoxIconEnable, isMulti, fieldName, error, helperText }) => {
    const containerRef = useRef(null);
    const { i18n: { language }, } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);
    const [inputValuea, setInputValuea] = useState('');

  // function for controlling dropdown menu open/close behavior
  const onDomClick = (e) => {
    
    let menu = containerRef.current?.querySelector(".select__menu");
    if (
      !containerRef?.current?.contains(e.target) ||
      !menu ||
      !menu?.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValuea("");
    }
  };


  // filter the option data
    //Custom search filter
    const customFilter = (o) => {
      if ( o.value.toLowerCase().includes(inputValuea?.toLowerCase()) ) {
        return true;
      } else {
        return false;
      }
    };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

    return (
      <Box>
      <Controller
      defaultValue=""
      control={control}
      name={fieldName}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => {
        const filterValue = options.filter(
          (cd) => cd.DataValue2 === value?.value
        );
        const selectedVal = filterValue?.length
          ? {
              value: filterValue[0]?.Id,
              label: (
                <div>
                  <div className="radius-4 me-2">
                    <img
                      src={filterValue[0].DataValue1}
                      style={{
                        width: "20px",
                      }}
                      alt="country flag"
                    />
                  </div>
                  <div>
                    <div
                      className="fs-14 light-font-text d-flex gap-1"
                      style={{
                        color: "rgba(0, 0, 0, 1)",
                        opacity: "1",
                      }}
                    >
                      <span>{filterValue[0]?.Id}</span>
                      <span>{filterValue[0]?.DataValue2}</span>
                    </div>
                  </div>
                </div>
              ),
            }
          : "";

        return (
          <Select  
          error={error} 
          className="react-select-search-dropdown-container"
          placeholder={<span className='paragraph2-light'>{placeholder}</span>}
          isSearchable={searchable}
          setInputValuea={setInputValuea}
          options={options.filter((f) => customFilter(f))} 
          styles={styles} 
          value={value}
          components={{
            MenuList: CustomMenuList,
            ValueContainer: CustomValueContainer,
            DropdownIndicator,
            Option,
          }}
          isClearable={false}
          backspaceRemovesValue={false}
          isMulti={isMulti}
          isCheckBoxIconEnable={isCheckBoxIconEnable}
          closeMenuOnSelect={!isCheckBoxIconEnable}
          hideSelectedOptions={!isCheckBoxIconEnable}
          onFocus={()=>  setIsFocused(true)}
          onMenuInputFocus={() => {
            setIsFocused(true)
          }}
          description={<span className='label-regular'>{description}</span>}
          onChange={(e)=>{
            onChange(e); 
            !isMulti && setIsFocused(false);
          }}
          {...{
            menuIsOpen: isFocused || undefined,
            isFocused: isFocused || undefined,
          }}
          /> 
        );
      }}
    />
        {
          error && <HelperText helperText={helperText} />
        }
      </Box>
      )
}


SearchDropdown.defaultProps = {
  searchable: false,
  clearable: false,
  disabled: false,
  isMulti: false,
  isCheckBoxIconEnable: false,
  noOptionsMessage: "No options",
};
