import styled, {css} from 'styled-components'
import ReactSlider   from 'react-slider'

export const Range = styled(ReactSlider)`
  width: calc(100% - 24px);
  height: 25px;
  cursor: pointer;
  display:flex;
  align-items:center;
`
export const Values = styled.div`
  width:100%;
  display:flex;
  position:relative;
  justify-content: space-between;
`
export const Value = styled.div( ({right}) => css`
  // height:25px;
  // width:25px;
  // display:flex;
  // justify-content:center;
  // line-height:25px;
  // text-align:center;
  // overflow:visible;
  // white-space:nowrap;
  // position:absolute;
  // // transform:${ ( right ) ? 'translatex(50%)' : 'translatex(-50%)' };
  // // left:${ ( right ) ? 'unset' : '12px' };
  // // right:${ ( right ) ? '13px' : 'unset' };
  // left:${ ( right ) ? 'unset' : '0' };
  // right:${ ( right ) ? '0' : 'unset' };
  color: ${({ clr }) => `${clr?.primary_text}`}
`)