import { Box } from "@mui/material";
import Downloads from "components/Downloads/Downloads";
import { useBreak } from "hooks/useBreak";
import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

export const ViewDownloads = () => {
  const isDesktop    = useBreak('md_up')

  return (
    <>
   {
      isDesktop ?
      <Scrollbar trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return <span {...restProps} ref={elementRef}
          className="trackY" style={{ ...restProps.style }} />;
        },
      }}>
        <RenderComponent />
      </Scrollbar>
      :
      <RenderComponent />
    }
    </>
  )
};


const RenderComponent = () => {
  return (
    <Box
      sx={{
        pb: {md:"0px", xs:"0px"}
      }}
    >
      <Downloads />
    </Box>
  );
}