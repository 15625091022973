import { Box, FormControl, Grid } from "@mui/material";
import { ReactSelect } from "components/AdvanceDropdown/NormalDropdown/Dropdown";
import { CustomLabel } from "components/Contact/styled-components";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { HelperText } from "./TextField";

export const SelectDate = ({ grid, dateOfBirth, setValue, getValues, watch, control, style }) => {
  const { handleSubmit, reset, formState: { errors, isValid } } = useForm({ mode: 'onChange' });

  // Generate options for days, months, and years
  const staticDays = Array.from({ length: 31 }, (_, i) => i + 1);
  const [days, setDays] = useState(Array.from({ length: 31 }, (_, i) => i + 1));

  const months = [
    "1", "2", "3", "4", "5", "6",
    "7", "8", "9", "10", "11", "12"
  ];
  
  const years = Array.from({ length: 100 }, (_, i) => new Date().getFullYear() - i);

  useEffect(() => {
    if (getValues('birth_year') && getValues('birth_year')?.value !== '' && getValues('birth_month') && getValues('birth_month').value !== '') {
      // Calculate the number of days in the selected month and year
      const daysInMonth = new Date(getValues('birth_year').value, parseInt(getValues('birth_month').value), 0).getDate();
      setDays(Array.from({ length: daysInMonth }, (_, i) => i + 1))
    } else {
      setDays(staticDays)
    }
    if(getValues('birth_year') && getValues('birth_year').value !== '' && getValues('birth_month') && getValues('birth_month').value !== '' && getValues('birth_date') && getValues('birth_date').value !== '') {
        setValue(dateOfBirth, getValues('birth_date').label + '-' + getValues('birth_month').label + '-' + getValues('birth_year').label )
    }
  }, [watch('birth_year'), watch('birth_month'), watch('birth_date')]);

  useEffect(()=> {
    if(!getValues('dateOfBirth')) {
        setValue('birth_year', '')
        setValue('birth_month', '')
        setValue('birth_date', '')
    }
  }, [getValues('dateOfBirth')])

  
  return (
  <Grid item container sx={{  pt: "0 !important", gap: '16px', ...style }}>
    <Grid sx={{ width: "150px" }}>
        <FormControl
            sx={{ gap: '8px' }}
            fullWidth
            variant='filled'
        >
            <CustomLabel shrink className="h5">
            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'formSelectQuestion5')[0]?.Label } */}
            Fødselsdato
            &nbsp;<span>*</span>
            </CustomLabel>
            <Controller
                control={control}
                name="birth_date"
                rules={{
                required: true,
                }}
                render={({ field: { onChange, value, ref, error } }) => {
                return (
                    <ReactSelect
                        error={error}
                        helperText={'dato'}
                        placeholder={ 'Dato' }
                        inputRef={ref}
                        options={days.map((day) => ({
                        value: day,
                        label: day,
                        }))}
                        value={value}
                        onChange={(e) => {
                        onChange(e);
                        }}
                    />
                )
                }}
            />
        </FormControl>
    </Grid>
    <Grid sx={{ width: "150px", display: 'flex', alignItems: 'flex-end' }}>
        <FormControl
            sx={{ gap: '8px' }}
            fullWidth
            variant='filled'
        >
            <Controller
                control={control}
                name="birth_month"
                rules={{
                required: true,
                }}
                render={({ field: { onChange, value, ref, error } }) => {
                return (
                    <ReactSelect
                        error={error}
                        helperText={'måned'}
                        placeholder={ 'Måned' }
                        inputRef={ref}
                        options={months.map((month) => ({
                        value: month,
                        label: month,
                        }))}
                        value={value}
                        onChange={(e) => {
                        onChange(e);
                        }}
                    />
                )
                }}
            />
        </FormControl>
    </Grid>
    <Grid sx={{ width: "150px", display: 'flex', alignItems: 'flex-end' }}>
        <FormControl
            sx={{ gap: '8px' }}
            fullWidth
            variant='filled'
        >
            <Controller
                control={control}
                name="birth_year"
                rules={{
                required: true,
                }}
                render={({ field: { onChange, value, ref, error } }) => {
                return (
                    <ReactSelect
                        error={error}
                        helperText={'år'}
                        placeholder={ 'År' }
                        inputRef={ref}
                        options={years.map((Year) => ({
                        value: Year,
                        label: Year,
                        }))}
                        value={value}
                        onChange={(e) => {
                        onChange(e);
                        }}
                    />
                )
                }}
            />
        </FormControl>
    </Grid>
        {/* <HelperText helperText={'please select valid date'} /> */}
</Grid>)
}