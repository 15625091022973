import { Box } from "@mui/material";
import { PlaceHolderInputField } from "components/Contact/styled-components";
import styled from "styled-components";

export const CustomPlaceHolderInputField = ({ error, helperText, label, placeholder, register }) => {
    return <PlaceHolderInputField
    error={error}
    label={label}
    helperText={error && <HelperText helperText={helperText} />}
    // inputProps={{ className: "paragraph2-light" }} 
    placeholder={placeholder}
    {...register}
    />
}


export const HelperText = ({ helperText }) => {
    return <Box sx={{ marginLeft: '4px', marginTop: '0px', position: "absolute" }}>
        <HelperTextContainer>
            <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                version="1.1"
                >
                <g
                    transform="translate(1.029601,0.99099099)">
                    <path
                    id="Vector"
                    d="M 4,8 C 5.06087,8 6.07828,7.57857 6.82843,6.82843 7.57857,6.07828 8,5.06087 8,4 8,2.93913 7.57857,1.92172 6.82843,1.17157 6.07828,0.421427 5.06087,0 4,0 2.93913,0 1.92172,0.421427 1.17157,1.17157 0.421427,1.92172 0,2.93913 0,4 0,5.06087 0.421427,6.07828 1.17157,6.82843 1.92172,7.57857 2.93913,8 4,8 Z M 4,2 c 0.20781,0 0.375,0.16719 0.375,0.375 v 1.75 C 4.375,4.33281 4.20781,4.5 4,4.5 3.79219,4.5 3.625,4.33281 3.625,4.125 V 2.375 C 3.625,2.16719 3.79219,2 4,2 Z M 3.5,5.5 C 3.5,5.36739 3.55268,5.24021 3.64645,5.14645 3.74021,5.05268 3.86739,5 4,5 4.13261,5 4.25979,5.05268 4.35355,5.14645 4.44732,5.24021 4.5,5.36739 4.5,5.5 4.5,5.63261 4.44732,5.75979 4.35355,5.85355 4.25979,5.94732 4.13261,6 4,6 3.86739,6 3.74021,5.94732 3.64645,5.85355 3.55268,5.75979 3.5,5.63261 3.5,5.5 Z"
                    fill="#c7393c" />
                </g>
                </svg>
            <span>
                {helperText}
            </span>
        </HelperTextContainer>
    </Box>
}

const HelperTextContainer = styled.div`
    color: #C7393C;
    font-family: Cerebri Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 4px;
`