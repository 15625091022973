import styled, {css} from 'styled-components'

export const Thumb = (props, state) => {   
  return (
    <StyledThumb {...props} index={state.index}>
      {state.index === 0 && <Right/> }
      {state.index === 1 && <Left/> }
    </StyledThumb>
  )
}

const StyledThumb = styled.div( () => css``)

const Fig = styled.svg`
  width:24px;
  height:24px;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
`
const Left = () => {
  
  const innerD = 'M7.9 0a7.9 7.9 0 1 1-5.5 2.3A7.9 7.9 0 0 1 7.9 0M9 12.4V4L4.9 8.2z'
  const outerD = 'M 5.3761715,8.4882242 C 4.9882917,8.2443464 4.9946113,7.7194275 5.3882767,7.4827558 L 9.5430251,4.9853513 C 9.987448,4.7182305 10.577989,5.0121703 10.572145,5.4976296 L 10.511105,10.5685 c -0.0058,0.485428 -0.603332,0.768444 -1.0412254,0.493183 z'
  const innerC = clr?.interval_thumb
  const outerC = clr?.interval_mark
  
  return (
    <Fig viewBox="0 0 15.8 15.8">
      <rect width="15.832871" height="15.717841" rx="7.9164357"  x="0" y="0"  fill={"#fff"} />
      <path d={outerD} fill={outerC} />
    </Fig>
  )
}
const Right = () => {
  
  const innerD = 'M8 0a7.9 7.9 0 1 0 5.5 2.3A7.9 7.9 0 0 0 8 0M6.8 12.4V4L11 8.2z'
  const outerD = 'm 10.539113,7.5413629 c 0.382299,0.2754705 0.382299,0.8772804 0,1.1527509 L 6.5043135,11.60094 c -0.4315936,0.310913 -1.012728,-0.0198 -1.012728,-0.576375 V 5.2109057 c 0,-0.5565353 0.5811344,-0.8872875 1.012728,-0.5763686 z'
  const innerC = clr?.interval_thumb
  const outerC = clr?.interval_mark
  
  return (
    <Fig viewBox="0 0 15.8 15.8">
      <rect width="15.832871" height="15.717841" rx="7.9164357"  x="0" y="0"  fill={"#fff"} />
      <path d={outerD} fill={outerC} />
    </Fig>
  )
}