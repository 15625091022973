import { Isometry }    from 'components/Isometry/Isometry'
import { IsometrySub } from 'components/Isometry/Subisometry'
import { useAtom } from 'jotai';
import { useParams }   from 'react-router-dom'
import {  toggle_drawer } from 'state/actions';
import { vis_desktop_nav, vis_detail, vis_filter } from 'state/store.global';

export const ViewIsometry = () => {
  const detailDrawer = useAtom(vis_detail)[0];
  const toggle = useAtom(toggle_drawer)[1];
  const sub = useParams('sub').sub
  
  const [, setShowFilter] = useAtom(vis_filter);
  const [, setShowMenu] = useAtom(vis_desktop_nav);

  return (
    <>
      <div style={{border:'0px solid gray',height:'calc(100vh - 60px)',position:'relative'}} onClick={()=> {
        detailDrawer && toggle("detail")
        setShowFilter(false);
        setShowMenu(false);
      }}>
         <Isometry/>
      </div>
    </>
  )
}