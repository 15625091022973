
import styled from 'styled-components'

export const RenderMark = (  props ) => {

  // Check if the current index should render a mark
  const fill = "#000"

  return (
    <Marking {...props} key={props.key} />
  )
}

export const Marking = styled.div`
  height: 6px;
  width: 6px;
  background: #B2B2B2;
  border-radius: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(15px, -50%);
`