import { styled, TextField } from "@mui/material";

export const styles = {
  container: (provided, state) => {
    return {
      ...provided,
      width: "300px",
      maxWidth: "100%",
    }},
  valueContainer: (provided, state) => {
    return {
      ...provided,
      fontSize: 14,
      fontFamily: "Cerebri sans-light",
      letterSpacing: 0,
    }},
  singleValue: (baseStyles)=> ({
    ...baseStyles,
    lineHeight: 2,
    fontSize: 14,
    "@media only screen and (max-width: 600px)": {
      fontSize: 16,
    },
    fontFamily: "Cerebri sans-light",
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: 14,
    fontWeight: 300,
    boxShadow: state.isFocused ? "none" : "none",
    borderRadius: "6px",
    backgroundColor: "#fff",
    borderColor: state.isFocused ? "#000" : state.selectProps.error ? "#C7393C" :  '#D1D1D1',
    "&:hover": {
      borderColor: "#D1D1D1",
      filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12))"
    },
    pointerEvents: state.isDisabled && "auto",
    cursor: state.isDisabled ? "not-allowed " : "pointer",
    minHeight: "44px",
  }),
  placeholder: (base, state) => ({
    ...base,
    cursor: "pointer",
    color: "#000"
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    width: "0px",
  }),
  menu: (baseStyles, state) => ({
      ...baseStyles,
      zIndex: 5,
      border: "1px solid #D1D1D1",
      overflow: "hidden",
      marginTop: "4px"
    }),
  option: (base, { isDisabled, isSelected, selectProps, ...state }) => {
    const getIndex = state?.options.findIndex(
      (s) => state?.data?.value === s?.value
    );
    return {
      ...base,
      backgroundColor: isSelected ? "#F9F9F9" : "#fff",
      zIndex: 1,
      fontSize: 14,
      fontFamily: "Cerebri sans-light !important",
      color: isDisabled ? "#888888" : "#000000",
      padding: "10px 16px",
      borderBottom:
        state?.options?.length - 1 === getIndex ? "" : "1px solid #D1D1D1",
      cursor: isDisabled ? "not-allowed" : "pointer",

      "&:active": {
        backgroundColor: '#F9F9F9'
      },
      "&:hover": {
        backgroundColor: '#F9F9F9'
      }
    };
  },
  clearIndicator: (baseStyles, state) => ({
    cursor: state.isDisabled ? "not-allowed " : "pointer",
  }),
  menuList: (baseStyles, state) => ({
    ...baseStyles,
    padding: '0',
    maxHeight: "175px", // Adjust the value to your desired maximum height
    overflowY: "auto", // Add vertical scroll if needed,
  }),
  dropdownIndicator: (base, state) => {
    return {
      ...base,
      color: "#000000",
      transition: "all .2s ease",
      transform: state.isFocused ? "rotate(180deg)" : null,
      padding: "10px 16px",
      cursor: "pointer",
    }
  },
}

export const PlaceHolderInputField = styled(TextField)(({ theme }) => {
  return ({
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px 6px 6px 0px",
    },
    "& .MuiInputBase-input": {
      borderRadius: "0px 6px 6px 0px",
      position: "relative",
      backgroundColor: "#FFF",
      fontSize: 16,
      width: "100%",
      padding: "10.5px 16px 10.5px 16px",
      height: "44px",
      transition: "border-color 0.3s",
    },
    "& .MuiInputLabel-formControl": {
      color: '#000',
      transform: "translate(14px, 11px) scale(1)"
    },
    "& .MuiInputLabel-formControl.Mui-focused": {
      color: '#A9A9A9',
      transform: "translate(14px, -9px) scale(0.75)"
    },
    "& .MuiFormLabel-filled.MuiInputLabel-formControl": {
      color: '#A9A9A9',
      transform: "translate(14px, -9px) scale(0.75)",
      // display: 'none'
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "0px 6px 6px 0px",
        border: "1px solid #D1D1D1",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #000 !important"
    }
  })
});
