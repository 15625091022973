import { useState, useRef, useEffect }   from 'react'
import { _map }               from 'state/store.map'
import { atom, getFocus, setFocus, useAtom, useGet } from 'state/jotai'
import { ZoomPanPinch }       from 'components/ZoomPanPinch/ZoomPanPinch'
import { Dropdown }           from 'components/Dropdown/Dropdown'
import { useMap }             from './Map.Hook'
import { useClick }           from './Map.Click'
// import { Cursor }             from './Map.Cursor'
// import { Center }             from './Map.Center'
import { Nav }                from './Map.Nav'
// import { Canvas }             from './Map.Canvas'
// import { Svg }                from './Map.Svg'
import { Fit }                from './Map.Fit'
import { Labels }             from './Map.Labels'
import useMeasure             from 'react-use-measure'
import { a_labHovered, active_project, current_language, drawer_animation_done, iso_free, iso_isrotating } from 'state/store.global'
import { _bootstrap } from 'state/store.bootstrap'
import { getIsoData, setIsoData } from 'state/jotai.isometryDataHooks'
import { _isometry } from 'state/store.isometry'
import { getProjectData } from 'state/jotai.projectDataHooks'
import { createZppSetup } from 'components/Isometry/Isometry.ZoomPanPinch'
import { Center } from './../Isometry/Isometry.Center';
import { Canvas } from './../Isometry/Isometry.Canvas';
import { Measure } from 'components/Isometry/Isometry.Measure'
import { Space } from 'components/Isometry/Isometry.style'
import { Recenter } from 'components/Isometry/isometry.Recenter'
import { Svg } from './../Isometry/Isometry.Svg';
import { Cursor } from './../Isometry/Isometry.Cursor';
import { Tiny } from 'components/Isometry/Isometry.Tiny';
import { ReactComponent as Etape1 } from 'assets/etape1.svg'
import { ReactComponent as Etape2 } from 'assets/etape2.svg'
import { ReactComponent as Etape3 } from 'assets/etape3.svg'
import { useHover } from 'hooks/useHover'
import { useNavigate } from 'react-router-dom'

export const hoveredBagdeAtom = atom('')

export const Map = () => {

  // const [measure, bounds] = useMeasure()
  
  // const views    = getFocus(_map, 'views')
  // const config   = getFocus(_map, 'config')
  // const init     = getFocus(_map, 'initial')
  // const scale    = getFocus(_map, 'activescale')
  // const ready    = getFocus(_map, 'ready')
  // const svgs     = getFocus(_map, 'svgs')
  
  // const setScale = setFocus(_map, 'activescale')
  
  // const [mode  , setMode  ] = useState(false)
  // const [cursor, setCursor] = useState('grab')
  // const [active, setActive] = useState(init)
  
  // const max    = views.length-1
  // const zppRef = useRef()
  // const canRef = useRef()
  // const dim    = config[active]?.DIMENSIONS
  // const point  = config[active]?.CENTER
  // const hook   = useMap(bounds,cursor,zppRef,dim,setScale,mode)
  // const click  = useClick()



  const navigateTo = useNavigate()
  const [measure, bounds] = useMeasure()
  const setLabHovered = useAtom(a_labHovered)[1];
  // const [hoverRef, isHovered] = useHover();

  const [hoverRefEtape1, isHoveredEtape1] = useHover();
  const [hoverRefEtape2, isHoveredEtape2] = useHover();
  const [hoverRefEtape3, isHoveredEtape3] = useHover();
  
  const [hoveredBadge, setHoveredBadge] = useAtom(hoveredBagdeAtom)
  const currentLanguage = useAtom(current_language)[0];

  const { pin, pid } = useGet(active_project)
  const projectType    = getFocus(_bootstrap, 'type')

  const showSVG = useGet(drawer_animation_done)

  const mode = true
  const tiny = getIsoData('tiny', 'src')
  const frames = getIsoData('frames')
  const percent = getIsoData('percent')
  const status = getIsoData('status')
  const config = getIsoData('config')
  const frame = getIsoData('activeframe')
  const svg = getIsoData('overlays', frame)
  const index = getIsoData('snapindex')
  const units = getProjectData('units', 'Units')

  const setIndex = setIsoData('snapindex')
  const setFrame = setIsoData('activeframe')
  const setScale = setFocus(_isometry, 'activescale')
  const scale = getFocus(_isometry, 'activescale')

  const zppRef = useRef()
  const canRef = useRef()
  const dim = { W: config?.IMAGE_W, H: config?.IMAGE_H }
  const point = config?.CENTER
  const zppsetup = createZppSetup(bounds, zppRef, dim, setScale, mode)
  const rmode = useGet(iso_free)
  const isrotate = useGet(iso_isrotating)

  useEffect(() => {
    if(isHoveredEtape1)  setLabHovered( { name: 'etape1' } );
    else if(isHoveredEtape2)  setLabHovered( { name: 'etape2' } );
    else if(isHoveredEtape3)  setLabHovered( { name: 'etape3' } );
    else setLabHovered( { name: '' } );
  }, [isHoveredEtape1,isHoveredEtape2,isHoveredEtape3]);

  return <>
    {/* <Cursor         {...{ ref:measure, setCursor }} >
      <Dropdown     {...{ mode }} /> 
      <Fit          {...{ setMode , mode }} /> */}
      {/* <Nav          {...{ setActive, max, active }} /> */}
      {/* <ZoomPanPinch {...{ ref:zppRef, ...hook }} >
        <Canvas     {...{ ref:canRef, active, ready, dim, views }} />
        <Svg        {...{ active, click, svgs }} />
        <Center     {...{ point }} />
        <Labels     {...{ scale, active, key:active }} />
      </ZoomPanPinch>
    </Cursor> */}
      <Cursor>
      <Space d={30} m={120}/>
      <Measure ref={measure}>
      <ZoomPanPinch {...{ ref:zppRef, ...zppsetup }}>
            <Tiny {...{ tiny, dim, status, percent }}/>
            <Canvas {...{ ref:canRef, frame, pid, status, dim, frames, config }} />
            <Center {...{ point }} />
            { !rmode && showSVG && <Svg {...{ svg, percent, units }} /> }
            <div  ref = {hoverRefEtape1} >
              <Etape1 id='etape1'onClick={(e)=>{ navigateTo(`/${currentLanguage}/isometry/${e.target.closest('svg').id }`) }} style={{zIndex:1000,width:260,position:'absolute',top:880,left:2290}}/>
            </div>
            {/* <div ref = {hoverRefEtape2} >
              <Etape2 id='etape2' onClick={(e)=>{ navigateTo(`/${currentLanguage}/isometry/${e.target.closest('svg').id }`) }} style={{zIndex:1000,width:260,position:'absolute',top:880,left:1450}}/>
            </div>
            <div ref = {hoverRefEtape3} >
              <Etape3 id='etape3' onClick={(e)=>{ navigateTo(`/${currentLanguage}/isometry/${e.target.closest('svg').id }`) }} style={{zIndex:1000,width:260,position:'absolute',top:690,left:370}}/>
            </div> */}
      </ZoomPanPinch>
      </Measure>
      <Space d={110} m={120}/>
      <Recenter z={zppRef} bounds={bounds} dim={dim}/>
      </Cursor>
  </>
}