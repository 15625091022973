import { useEffect, useState } from 'react';
import { Main, Control, Text } from "./OldSnapSlider.style";
import { getFocus, useAtom, useGet, useSet } from 'state/jotai';
import { active_project, iso_isrotating, northDegree } from 'state/store.global';
import { vis_overlay } from 'state/store.global';
import { motion } from 'framer-motion';
import { _project } from 'state/store.projects';
import { useBreak } from 'hooks/useBreak';
import { RenderMark } from "./OldSliderMark";
import { Thumb } from './OldSliderThumb';
import { Track } from './OldSliderTrack';

export const OldSnapSlider = ({ frame, setFrame, config, index, setIndex }) => {
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];

  const isDesktop = useBreak('md_up');
  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3, ease: 'linear' },
  };

  const ini = config.FIRST_IMAGE;
  const max = config.FIRST_IMAGE + config.IMAGE_NUM - 1;
  const end = config.IMAGE_NUM - 1;
  const marks = config.IMAGE_SNAPFRAMES;

  const [render, setRender] = useState(false);
  const setOverlay = useSet(vis_overlay);
  const setRotate = useSet(iso_isrotating);
 
  const change = v => setFrame(v);
  const northDeg = useAtom(northDegree)[0];
  const sliderIndex = frame - ini;
 
  const [p, setP] = useState(0);
 
  const onReleaseHandler = (value) => {
    let closestMark = marks.reduce((prev, curr) =>
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
    let snapIndex = marks.indexOf(closestMark);
    setFrame(closestMark);
    setIndex(snapIndex);
    setP(snapIndex);
    // console.log('Slider released at value:', closestMark, snapIndex);
  };

  useEffect(() => {
    setTimeout(() => {
      setRender(true);
    }, 1000);
    setOverlay(true);
    return () => {
      setOverlay(true);
    };
  }, []);

  if (!isNaN(end)) {
    return (
      <Main
        {...animation}
        onTouchStart={() => setRotate(false)}
        onTouchMove={() => setRotate(false)}
        onMouseDownCapture={() => setRotate(true)}
        
      >
        {render && (
          <motion.div {...animation} style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
            <Control
              isDesktops={isDesktop}
              value={frame}
              defaultValue={frame}
              renderTrack={Track}
              renderThumb={Thumb}
              renderMark={RenderMark}
              step={1}
              max={max}
              min={ini}
              marks={[...marks]}
              onSliderClick={() => setRotate(false)}
              onChange={change}
              onAfterChange={onReleaseHandler} // Correctly use onAfterChange
            />
          </motion.div>
        )}
      </Main>
    );
  }

  return null; // Add a fallback return to handle the case when end is NaN
};
