import { useGet } from 'state/jotai'
import { active_project } from 'state/store.global'
import styled from 'styled-components/macro'
const trackH = 15
const trackX = 15
  const before =0
  const after = 0
  const trackBG = "#F3F3F3"
export const Track = ( props, state ) => {
 
  const fill = clr.iso_slider_track
  
  // console.log(props, state)
  return (
    <TrackStyle 
      {...props} 
      fill={fill}
      // index={state.index}
      key={props.key} />      
  )

}

const StyledTrack = styled.div`
  /* background: ${({ index }) =>
    index === 2 ? '#f00' : index === 1 ? '#0f0' : '#ddd'}; */
  /* background: #F3F3F3; */
  background: ${({ fill }) => fill};
  border-radius: 29px;
  height: 4px;
  top: 50%;

  
  transform: translatey(-50%);
`
export const TrackStyle = styled.div`
  height:${trackH}px;
  background-color:${trackBG};
  top: 50%;
  transform: translatey(-50%);
    border-radius: 29px;

  //   ::before {
  //   background-color:${trackBG};
  //   width:${before}px;
  //   height:${trackH}px;
  //   position:absolute;
  //   top:0px;
  //   left:-${before}px;
  //   border-top-left-radius:199px;
  //   border-bottom-left-radius:199px;
  //   content:" ";
  // }
  // ::after {
  //   background-color:${trackBG};
  //   width:${after}px;
  //   height:${trackH}px;
  //   position:absolute;
  //   top:0px;
  //   right:-${after}px;
  //   border-top-right-radius:199px;
  //   border-bottom-right-radius:199px;
  //   z-index:-1;
  //   content:" ";
  // }
`