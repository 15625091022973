import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import { CheckBox } from "components/FormComponent/CheckBox";
import React, { useState } from "react";
import { components } from "react-select";
import styled from "styled-components";

const { MenuList, ValueContainer, Placeholder } = components;

export const CustomMenuList = ({ selectProps, ...props }) => {
    const {
      onInputChange,
      inputValue,
      onMenuInputFocus,
      setInputValuea,
      description,
    } = selectProps;
  
    const [search, setSearch] = useState("");
   
    return (
      <div>
        <Box sx={{ padding: "16px", display: "flex", flexDirection: "column", gap: "8px", borderBottom: "1px solid #D1D1D1" }}>
        <Box sx={{ color: "#000"}} className={"label-regular"}>
          {description ? description : "Search here"}
        </Box>
        <Box>
            <SearchBox>
                <div className="country-search-input">
                <input
                    type="text"
                    value={search}
                    onKeyDown={(e) => {
                    if (e.code === 'Backspace') {
                        setSearch((search) => search.slice(0, search.length-1));
                        setInputValuea((prev) => prev?.slice(0, prev?.length-1));
                    }
                    }}
                    onChange={(e) => {
                    setSearch(e.currentTarget.value);
                    setInputValuea(e.currentTarget.value);
                    }}
                    onMouseDown={(e) => {
                    e.stopPropagation();
                    e.target.focus();
                    }}
                    onTouchEnd={(e) => {
                    e.stopPropagation();
                    e.target.focus();
                    }}
                    onFocus={onMenuInputFocus}
                    placeholder="Search..."
                />
                </div>
                <div className="country-search-icon">
                <FontAwesomeIcon
                    icon="fa-regular fa-magnifying-glass"
                    style={{ color: "#222222", cursor: 'pointer' }}
                />
                </div>
            </SearchBox>
        </Box>
        </Box>
        <MenuList {...props} selectProps={selectProps} />
      </div>
    );
};

export const Option = (props) => {
  const isCheckBoxIconShowEnable = props.selectProps.isCheckBoxIconEnable
  return (
    <div>
      <components.Option {...props}>
        { isCheckBoxIconShowEnable &&
          <CheckBox 
            checked={props.isSelected}
            onChange={() => null}
          />
        }
        <label className="paragraph2-light" style={{ cursor: props.isDisabled ? 'not-allowed' : 'pointer' }}>{props.label}</label>
      </components.Option>
    </div>
  );
};

// Set custom `SingleValue` and `Placeholder` to keep them when searching
export const CustomValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();

  let selectedItemsLength = 0;
  let toBeRendered = children;
  if (currentValues.length > 1) {
    toBeRendered = children[0].slice(0, 2);
    selectedItemsLength = currentValues.length - 2;
  }
  
    return (
      <ValueContainer {...props} style={{ cursor: "pointer" }}>
        { !currentValues.length &&
          <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        }
        <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          height: currentValues.length ? 'auto' : 0,
          width: "199px",
          maxWidth: "100%",
          overflow: "hidden"
        }}
      >
        {React.Children.map(children, (child) =>
          child && child.key !== "placeholder" ? 
          <div>
            {child} 
          </div>
          : null
        )}
        </div>
        { selectedItemsLength > 0 &&
          <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            borderRadius: "2px",
            color: "hsl(0, 0%, 20%)",
            fontSize: "85%",
            "padding": "3px;",
            paddingLeft: "6px"
          }}
        >
          <span>+</span> {selectedItemsLength}
          </div>
        }
          {/* {React.Children.map(children, (child) =>
          child && child.key !== "placeholder" ? 
          <div style={{       
            display: "flex",
            height: currentValues.length ? 'auto' : 0,
            flexWrap: "nowrap", // Ensures values stay in a single row 
            }}>
            {child} 
          </div>
          : null
        )} */}
      </ValueContainer>
    );
  };

export const DropdownIndicator = (props) => {
  return (
      components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
          <svg width="12" height="6" viewBox="0 0 14 8" fill="none">
            <g>
              <path id="Path" d="M1 1L6.67692 6.85573C6.76068 6.94778 6.87761 7 7 7C7.12239 7 7.23932 6.94778 7.32308 6.85573L13 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
          </svg>
      </components.DropdownIndicator>
      )
  );
};

const SearchBox = styled.div`
    display: flex;
    border-radius: 6px;
    border: 1px solid #D1D1D1;
    height: 40px;
    align-items: center;
    padding-left: 16px;
    .country-search-input {
        width: 100%;

        input {
            border: none;
            outline: none;
            color: #202020;
            width: 100%;

            ::placeholder {
                color: #A9A9A9 !important;
            }
        }
    }
    .country-search-icon {
            padding-right: 16px;
    }
`