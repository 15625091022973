import { useGet, getFocus }     from 'state/jotai'
import DoneIcon                 from "@mui/icons-material/Done";
import { Box, Divider }         from "@mui/material";
import React                    from "react";
import { active_project }       from 'state/store.global'
import { _project }             from 'state/store.projects'

const SuccessMessage = ({width = "100%", label, msg, labelName}) => {
  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  return (
    <Box sx = {{ pt: 4, width: width }}>
    <Box sx = {{ textAlign: "center" }}>
        <Box
          sx={{
            width          : 60,
            height         : 60,
            mb             : 5,
            backgroundColor: "success.main",
            color          : "#ffffff",
            borderRadius   : "50%",
            display        : "inline-flex",
            alignItems     : "center",
            justifyContent : "center",
          }}
        >
          <DoneIcon
            sx={{
              fontSize: 40,
            }}
          />
        </Box>
      </Box>

      <Box>
        <Box mb = {2} sx={{ textAlign: 'center', color: "#fff", padding: '8px' }}>
          <strong style={{ fontFamily: "Cerebri sans" }}>
            {
              label ? buttonsLabel?.filter( btn => btn.UILabelProperty === labelName)[0]?.Label 
              : <div dangerouslySetInnerHTML={{ __html: msg }}></div>
            }
          </strong>
        </Box>
        {/* <Box mb = {2}>
          <strong>
          { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormSubmit1')[0]?.Label }
          </strong>
        </Box>
        <Box mb = {2}>
        { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormSubmit2')[0]?.Label } instagram @unity.aarhus
        </Box> */}
        {/* <Box>Vi ser frem til at fortælle dig mere.</Box> */}
      </Box>

      {/* <Divider
        sx={{
          mt         : 6,
          borderColor: "rgba(0, 0, 0, 0.2)",
        }}
      /> */}
    </Box>
  );
};

export default SuccessMessage;
