import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import Unit from "components/Unit/Unit.model";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomInput, CustomLabel, CustomRadioGroup, CustomSelect, PlaceHolderInputField  } from "./styled-components";
import SuccessMessage from "./SuccessMessage";
import useHubspot from "./useHubspot";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useLocation } from "react-router-dom";
import { useFetch } from "hooks/fetch/useFetch";
import { motion } from "framer-motion";
import axios from "axios";
import { Icon } from "components/Icons";
import { ArrowDropDownIcon } from "./FormComponent";
import { ReactSelect } from "components/AdvanceDropdown/NormalDropdown/Dropdown";
import { PhoneNumberDropdown } from "components/AdvanceDropdown/PhoneNumberDropdown/Dropdown";
import { CheckBox } from "components/FormComponent/CheckBox";
import { SearchDropdown } from "components/AdvanceDropdown/SeachDropdown/Dropdown";
import { CustomPlaceHolderInputField } from "components/FormComponent/TextField";
import { CustomRadio } from "components/FormComponent/Radio";
import { CustomButton } from "components/Unit/NewReservationButton";
import { enqueueSnackbar } from "notistack";
import EusButton from "components/Button/EusButton";
import { useBreak } from "hooks/useBreak";
import { GETLEADTOKEN } from "api/api";
import { SelectDate } from "components/FormComponent/SelectDate";


const ContactForm = ({ grid, unit, campaignId, children }) => {
  const sm       = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { i18n: { language }, } = useTranslation();

  const [personName, setPersonName] = useState([]);
  const [nationName, setNationName] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [describeName, setDescribeName] = useState([]);

  // const { submit, successMsg, errorMsg, inProgress, submitted } = useHubspot();
  const { register, handleSubmit, getValues, setValue, watch, control, reset, formState: { errors, isValid } } = useForm({ mode: 'onChange',
    defaultValues: {
      dateOfBirth: "",
      birth_date: "",
      birth_month: "",
      birth_year: ""
    },
   });
  const here = useLocation().pathname;
  const atUnit = here.includes("unit");
  const { pin, pid } = useGet(active_project) 
  const isMobile = useBreak('md_dn')

  const testLeadData = useFetch({ url: GETLEADTOKEN() });

  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const isDesktop = useMediaQuery('(min-width:1200px)');

  const unitNo = unit?.getValue("Projeknr") ? `${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Bolignr')[0]?.Label} ${unit.getValue("Projeknr")},` : "";
  const unitAddress = unit?.getAddress() ?? "";
  // const unitId = unit?.getId();
  const sys_uid = unit?.getValue('SVGID');
  
  const homeUrl = window.location.origin;
  const unitUrl = unit?.getUrl(`${homeUrl}/${language}`) ?? "";
  
  
  const [checked, setChecked] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);

  const handleCheckbox = (event) => { setChecked(event.target.checked); };


  useEffect(()=> {
      testLeadData.mutate()
  }, [language])

  useEffect(() => {
    if (submitted) {
      reset();
      setChecked(false);
      setChecked2(false)
    }
  }, [submitted]);

  const handleFormSubmit = async (data) => {


  let utmParam = {};
  for (let [key, value] of new URLSearchParams(location.search).entries()) {
    if(key === "utm_source" || key === "utm_medium" || key === "utm_campaign" || key === "utm_content") {
      utmParam[key] = value;
    }
  }
 
    // const leadNationality = testLeadData?.D?.countries?.filter((c) => c?.name_dk === data?.nationality_id || c?.name_eng === data?.nationality_id)
    // const leadCountry_code = testLeadData?.D?.countries?.filter((c) => c?.country_code === `+${data?.country_id.split("+")?.[1]}`)
    // const leadNationality = data?.nationality_id?.country_id

    const leadCountry_code = data?.country_id?.country_id
      // console.log(data,'data')
        const intersted = data['what_are_u_intersted_checkbox1-1'] && data['what_are_u_intersted_checkbox1-2'] ? "24" : data['what_are_u_intersted_checkbox1-1'] ? "19" : data['what_are_u_intersted_checkbox1-2'] ? "20" : "" 
  
        const payload = new URLSearchParams({
          Token: testLeadData?.D?.token,
          Hash: testLeadData?.D?.hash,
          Campaign: campaignId,
          SYS_UID: sys_uid,
          Fornavne: data.firstname,
          Efternavn: data.lastname,
          Email_privat: data.email,
          // SELECTIONS: `0,0,0,${data['what_are_u_intersted_checkbox1-1'] ? "19" : ''},${data['what_are_u_intersted_checkbox1-2'] ? "20" : ""}`,

          SELECTIONS: `0,0,0,${intersted}`,
          country_code: leadCountry_code,
          Telefon_mobil: data.phone,
          dob: data.dateOfBirth,
          postnummer: data.postnummer
          // Comments: data.kommentar?.length ? data.kommentar : "",
          // ...utmParam
        });
        // console.log(Object.fromEntries(payload))
        const peytzmailPayload = {
          "subscribe": {
          "mailinglist_ids": ["weekly-newsletter"],
          "subscriber": {
            "Token": testLeadData?.D?.token,
            "Hash": testLeadData?.D?.hash,
            "Campaign": campaignId,
            "SYS_UID": sys_uid,
            "Fornavne": data.firstname,
            "Efternavn": data.lastname,
            "Email_privat": data.email,
            "SELECTIONS": `${data['what_are_u_intersted_checkbox1-1'] ? "19" : ''},${data['what_are_u_intersted_checkbox1-2'] ? "20" : ""}`,
            "country_code": leadCountry_code,
            "Telefon_mobil": data.phone,
            "dob": data.dateOfBirth
          },
          "tags": ["campaign-spring"],
          "skip_confirm": false,
          "skip_welcome":false
          }
        }
        // console.log(peytzmailPayload)
      axios.post(`${testLeadData?.D?.system_domain}/api2/post_leadtool/`, payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((data) => {
        setSubmitted(Object.keys(data || {}).length ? true : false);
        testLeadData.mutate()
        setPersonName([]);
        setNationName([]);
        setDescribeName([])
        reset();
        setChecked(false);
        setChecked2(false)
      })
      // .catch((err) => {
      //   enqueueSnackbar(language === "da" ? 'Noget gik galt. Prøv igen' : 'Something went wrong. Try again', { variant: 'error' });
      // });
      // console.log(JSON.stringify(peytzmailPayload))

      const mailIdlist = [];
      if(data['what_are_u_intersted_checkbox1-1']) mailIdlist.push('lejer')
      if(data['what_are_u_intersted_checkbox1-2']) mailIdlist.push('ejer')

      const subscribeData = {
        subscribe: {
          mailinglist_ids: [...mailIdlist],
          "subscriber": {
            "first_name": data.firstname,
            "last_name": data.lastname,
            "landekode": leadCountry_code,
            "telefon": data.phone,
            "fodselsdato": data.dateOfBirth,
          },
          tags: ["campaign-spring"],
          skip_confirm: false,
          skip_welcome: false
        }
      };
      
      // const subscribeToMailingList = async () => {
      //   const apiKey = 'iPdL3yZAqrGE2vD-YzE9'; // This is the API key or username you're using
      //   const base64Auth = btoa(`${apiKey}:`)
      //   try {
      //     const response = await fetch('http://localhost:6001/proxy/subscribe', {
      //       method: 'POST',
      //       headers: {
      //         'Content-Type': 'application/json',
      //         // Authorization: `Basic ${base64Auth}` // Replace with your actual API key
      //       },
      //       body: JSON.stringify(subscribeData)
      //     });
      
      //     if (!response.ok) {
      //       throw new Error(`Error: ${response.status} ${response.statusText}`);
      //     }
      
      //     const responseData = await response.json();
      
      
      //     // Handle success or other responses
      //     if (responseData.result === 'created') {
      //       // console.log('Subscriber successfully created:', responseData.subscriber);
      //     } else {
      //       console.log('Unexpected response:', responseData.message);
      //     }
      //   } catch (error) {
      //     console.error('Error subscribing:', error.message);
      //   }
      // };
      
      // // Call the function
      // subscribeToMailingList();
      
  };

  const onSubmit = (data) => {
    handleFormSubmit(data);
  };

  // select dropdown configs
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;


  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: !grid ? "right" : "left" // Don't pass grid from contact drawer as this condition won't work
    },
    transformOrigin: {
      vertical: "top",
      horizontal: !grid ? "right" : "left"
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        // width: dropdown_width
      },
    },
  };

  // handling the field which should have only number allowed
  const handleNumberInput = (event) => {
    return (event.key.charCodeAt() >= 48 && event.key.charCodeAt() <= 57) || event.key === 'Backspace' ? event.key : event.preventDefault();
  }

  return (
    <>
      {submitted ? (
          <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
             <SuccessMessage label={atUnit ? false : false} labelName = {"Signupmessage"} msg={ 'Vi har modtaget din opskrivning - vi kontakter dig hurtigst muligt' } />
        </motion.div>
     
      ) : (
        <>
          <Box
            sx={{
              position: 'relative',
               color   : clr.sub_text_color,
            }}
          >
            {children}
            <form onSubmit={handleSubmit(onSubmit)} style={{ background: '#ffffffe6', borderRadius: '6px', padding: '16px' }}>
              <Grid
                container
                columnSpacing={3}
                rowSpacing={grid ? 2 : 1}
                sx={{ mb: 3 }}
              >
                <Grid item xs={12}>
                <Grid sx={{ gap: "16px" }}>
                  <FormControl
                    sx={{ mb: 2, gap: '8px' }}
                    fullWidth
                    variant='filled'
                  >
                    <CustomLabel shrink  className="h5">
                      {buttonsLabel?.filter( btn => btn.UILabelProperty === 'FullName')[0]?.Label || 'Fuldt navn'}&nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomPlaceHolderInputField 
                    error={errors.firstname ? true : false}
                    helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'firstNameErrorWarn')[0]?.Label || 'Indtast venligst et gyldigt fornavn'}
                    // label= { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FirstMiddleName')[0]?.Label }
                    label={buttonsLabel?.filter( btn => btn.UILabelProperty === 'FirstMiddleName')[0]?.Label || 'Fornavn og mellemnavn'}
                    register={{...register('firstname', { required: true, validate: value => value.trim() !== "" })}}
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomPlaceHolderInputField 
                    helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'lastNameErrorWarn')[0]?.Label || 'Indtast venligst et gyldigt'}
                    error={errors.lastname ? true : false}
                    label={buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEfternavn')[0]?.Label}
                    register={{...register('lastname', { required: true, validate: value => value.trim() !== "" })}}
                    />
                  </FormControl>
                </Grid>
                </Grid>
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                <Grid sx={{ pt: "0 !important" }}>
                  <FormControl
                    sx={{ mb: '24px', gap: '8px' }}
                    fullWidth
                    variant='filled'
                  >
                    <CustomLabel shrink  className="h5">
                    {buttonsLabel?.filter( btn => btn.UILabelProperty === 'PrivateEmail')[0]?.Label || "Privat email"}&nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomPlaceHolderInputField
                      error={errors.email ? true : false}
                      helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'emailAddressErrorWarn')[0]?.Label || 'Indtast venligst en gyldig e-mailadresse'}
                      label={buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEmail')[0]?.Label}
                      register={{...register('email', { required: true,  validate: (value) => {
                        const trimmedValue = value.trim();  // Trim spaces before and after the email
                        if (!trimmedValue) {
                          return false
                        }
                        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailPattern.test(trimmedValue)) {
                          return false
                        }
                        return true;
                      } })}}
                    />
                  </FormControl>
                </Grid>
                </Grid>
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                <Grid sx={{ gap: "16px" }}>
                  <FormControl
                    sx={{ mb: 3, gap: '8px' }}
                    fullWidth
                    variant='filled'
                  >
                    <CustomLabel shrink  className="h5">
                    {buttonsLabel?.filter( btn => btn.UILabelProperty === 'PhoneNumber')[0]?.Label || "Telefon nr."}&nbsp;<span>*</span>
                    </CustomLabel>
                    <PhoneNumberDropdown error={errors.country_id || errors.phone ? true : false} helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'dropdownErrorWarn')[0]?.Label} options={testLeadData?.D?.countries} control={control} getPhoneId={'country_id'} getPhoneNumber={'phone'} placeholder={ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Country')[0]?.Label || 'Land' } description={buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeDropdownDescription')[0]?.Label || "Søg landekode"} />
                  </FormControl>
                </Grid>
                </Grid>
                <SelectDate style={{ mb: 3, gap: '8px' }} setValue={setValue} getValues={getValues} watch={watch} control={control} dateOfBirth={'dateOfBirth'} />
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                <Grid sx={{ pt: "0 !important" }}>
                  <FormControl
                    sx={{ mb: '24px', gap: '8px' }}
                    fullWidth
                    variant='filled'
                    
                  >
                    <CustomLabel shrink  className="h5">
                    Postnummer&nbsp;<span>*</span>
                    </CustomLabel>
                    <Controller
                      defaultValue=""
                      control={control}
                      name={'postnummer'}
                      rules={{ required: true }}
                      render={({ field: { error, name, value, onChange } }) => {
                        return (
                          <PlaceHolderInputField 
                            error={error}
                            label={language === "da" ? "postnummer" : "Postal no"}
                            name={name}
                            value={value}
                            onChange={onChange}
                            onKeyDown={handleNumberInput}
                            type="tel"
                            />
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                </Grid>
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                <Grid sx={{ pt: "0 !important" }}>
                  <FormControl
                    sx={{ gap: '8px' }}
                    fullWidth
                    variant='filled'
                    
                  >
                    <CustomLabel shrink  className="h5">
                    Hvad er du interesseret i?&nbsp;<span>*</span>
                    </CustomLabel>
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                    <Controller
                      defaultValue=""
                      control={control}
                      name={'what_are_u_intersted_checkbox1-1'}
                      rules={{ required: !watch('what_are_u_intersted_checkbox1-2') }}
                      render={({ field: { error, name, value, onChange } }) => {
                        return (
                          <FormControlLabel
                          sx={{
                            alignItems: 'center',
                            mr: 0,
                            ml: 0,
                            gap: '8px',
                            "span":{    color: '#000' },
                            "a":{color: `${'#000'} !important` },
                          }}
                          control={
                            <CheckBox
                              name={name}
                              // checked={checked}
                              onChange={(onChange)}
                              sx={{ p: 0 }}
                            />
                          }
                          label={<p className="paragraph2-light">Lejebolig</p>}
                        />
                        )
                      }}
                      />
                       <Controller
                      defaultValue=""
                      control={control}
                      name={'what_are_u_intersted_checkbox1-2'}
                      rules={{ required: !watch('what_are_u_intersted_checkbox1-1')  }}
                      render={({ field: { error, name, value, onChange } }) => {
                        return (
                          <FormControlLabel
                          sx={{
                            alignItems: 'center',
                            mr: 0,
                            ml: 0,
                            gap: '8px',
                            "span":{    color: '#000' },
                            "a":{color: `${'#000'} !important` },
                          }}
                          control={
                            <CheckBox
                              name={name}
                              // checked={checked}
                              onChange={onChange}
                              sx={{ p: 0 }}
                            />
                          }
                          label={<p className="paragraph2-light">Ejerbolig</p>}
                        />
                        )
                      }}
                      />
                    </Box>
                 
                      </FormControl>
                </Grid>
                </Grid>
              </Grid>
              <Box sx={{ mb: 3, display: "flex", flexDirection: "column", gap: "8px" }}>
                <CustomLabel className="h4">
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelser')[0]?.Label }&nbsp;<span>*</span>
                </CustomLabel>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                <FormControlLabel
                  sx={{
                    alignItems: 'center',
                    mr: 0,
                    ml: 0,
                    gap: '8px',
                    "span":{    color: '#000' },
                    "a":{color: `${'#000'} !important` },
                  }}
                  control={
                    <CheckBox
                      name='_value'
                      checked={checked}
                      onChange={handleCheckbox}
                      sx={{ p: 0 }}
                    />
                  }
                  label={
                    <Box >
                      <div>
                        <span className="paragraph2-light">{language === "en" ? "Accept our terms and conditions." : "Accepter vores vilkår og betingelser"}&nbsp;</span>
                        <a className="h5" style={{ textDecoration: 'none' }} href={"https://faelledby.dk/privatlivspolitik-faelledby"} target="_blank">{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'ReadMoreHere')[0]?.Label || 'Læs mere her' }</a>.&nbsp;
                        <br />
                        <span className="paragraph2-light">Du kan til enhver tid afmelde dig nyhedsbrevet.</span>
                      </div>
                    </Box>
                  }
                />
                </Box>
              </Box>
              <Box
                sx={{
                  position: { xs: atUnit ? 'unset' :"fixed", sm: "unset" },
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  zIndex: '5',
                  "button": {
                    width: { xs: atUnit ? 'auto' : "100%", sm: "fit-content" },
                    height: { xs: !atUnit ? "60px" : "44px" , sm: "44px"},
                    padding: { xs: !atUnit ? "12px 40px 30px 40px" : "12px 40px" }
                  }
                }}
              >
              {
                sm && !atUnit 
                ?
                <EusButton
                // {...props}
                clr={clr}
                type='submit'
                onClick={()=> {}}
                borderRadius={"6px 6px 0px 0px;"}
                variant={"primary"}
                iconName={"next-arrow"}
                backgroundColor={clr.buttons}
                disabled={!checked || !isValid }
                icon={true}
                size={12}
                iconAlign={"right"}
                buttonlabel={false}
                otherText={'Send'}
                iconSize={"xl"}
                isFaIcon={false}
              />
                :
                <CustomButton
                type='submit'
                className="eus-button-2"
                clr={clr}
                disabled={!checked || !isValid}
                variant = "primary"
                padding={"12px 24px"}          
              >
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'signUp')[0]?.Label || 'Tilmeld' }
              </CustomButton>
              }
              </Box>
            </form>
          </Box>
        </>
      )}
    </>
  )
};

ContactForm.propTypes = {
  children: PropTypes.node,
  grid: PropTypes.bool,
  unit: PropTypes.instanceOf(Unit),
};

export default ContactForm;
