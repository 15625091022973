import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { PoweredBy } from 'components/PoweredBy/PoweredBy';
import { Menu, Tile, Split } from './Nav.Mobile.Foldout.style';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { _project } from "state/store.projects";
import { active_project } from "state/store.global";
import { getFocus, useGet } from "state/jotai";
import { Icon } from 'assets/Icons_';
import { Divider } from '@mui/material';
import { getProjectData } from 'state/jotai.projectDataHooks';

export const Foldout = ({ nav, show, setShow }) => {
  const { pin, pid } = useGet(active_project);
  const menus = getFocus(_project, "menuItems")?.[pin]?.[pid] ?? [];
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];
  const downloads = getProjectData('downloads', 0, []);

  const { i18n: { language } } = useTranslation();

  const menu = {
    initial: { y: '-100%' },
    animate: { y: 60 },
    exit: { y: '-100%' },
    transition: { duration: 0.3 },
  };

  const dynamicFilter = (item, downloadsLength) => {
    if (item.Order === "10") return false;
    if (downloadsLength === 0 && item.Order === "9") return false;
    return true;
  };

  const menusItemsFiltered = menus.filter((item) => dynamicFilter(item, downloads.length));

  // Convert keys to lowercase and ensure unique items based on text property
  const menusItems = [];
  const seenTexts = new Set();

  menusItemsFiltered.forEach(items => {
    const item = {};
    for (const key in items) {
      if (items.hasOwnProperty(key)) {
        item[key.toLowerCase()] = items[key];
      }
    }
    if (!seenTexts.has(item.text)) {
      seenTexts.add(item.text);
      menusItems.push(item);
    }
  });

  return (
    <AnimatePresence>
      {show && 
        <Menu
          as={motion.div}
          clr={clr}
          {...menu}>
          <div 
            style={{
              color: clr?.primary_text,
              padding: "16px", 
              borderBottom: `1px solid ${clr.primary_text}`, 
              display: 'flex', 
              justifyContent: "space-between", 
              alignItems: "center", 
              marginBottom: "16px"
            }}
          >
            <span className='h5' style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              {
                buttonsLabel?.filter(
                  (btn) => btn?.UILabelProperty === "Hvorvildugernehen"
                )[0]?.Label || "Hvor vil du gerne hen?"
              }
            </span>
            <Icon 
              icon='close' 
              isFaIcon={true} 
              click={() => setShow(false)} 
              size="xl" 
              color={clr?.primary_text} 
            />
          </div>
          {
            menusItems.map(({ url, text, icon }, j) => {
              const ico = { 
                href: url[language], 
                icon, 
                size: "", 
                color: clr?.primary_text 
              };
              return (
                <div 
                  style={{ padding: "0 24px" }}
                  key={text} 
                  onClick={() => setShow(false)} 
                >
                  <Link to={url} key={text} style={{ textDecoration: 'none' }}>
                    <Tile clr={clr}>
                      <div style={{ display: "flex" }}>
                        <span>
                          <Icon {...ico} isFaIcon={false} FaIconColor={clr.primary_text} size="25" />
                        </span>
                        <span className='paragraph1-regular' style={{ paddingLeft: "8px" }}>{text}</span>
                      </div>
                    </Tile>
                  </Link>
                  <Divider sx={{ background: clr.primary_text }} />
                </div>
              );
            })
          }
          <div className='eus-credits' style={{ display: 'flex', justifyContent: 'center', marginTop: 'auto', marginBottom: '16px' }}>
            <PoweredBy clr={clr.primary_text} />
          </div>
        </Menu>
      }
    </AnimatePresence>
  );
};
