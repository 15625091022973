import Select from 'react-select'
import { styles } from './Dropdown.Style'
import { Box } from '@mui/material';
import { HelperText } from 'components/FormComponent/TextField';
import { DropdownIndicator, CustomValueContainer, Option } from './CustomMenulist';
import { useState } from 'react';

export const ReactSelect = ({ searchable, placeholder, options=[], value, onChange, error, helperText, isMulti, isCheckBoxIconEnable }) => {

    return (
      <Box>
        <Select
          isMulti={isMulti}
          error={error}
          isClearable={false}
          // inputProps={{ className: "paragraph2-light" }} 
          placeholder={<span className='paragraph2-light'>{placeholder}</span>}
          isSearchable={searchable}
          options={options} 
          styles={styles} 
          value={value}
          onChange={(e)=> {
            onChange(e);
          }}
          isCheckBoxIconEnable={isCheckBoxIconEnable}
          closeMenuOnSelect={!isCheckBoxIconEnable}
          hideSelectedOptions={!isCheckBoxIconEnable}
          components={{
            DropdownIndicator,
            Option,
            ValueContainer: CustomValueContainer,
          }}
        />
        {
          error && <HelperText helperText={helperText} />
        }
      </Box>
      )
}


ReactSelect.defaultProps = {
  searchable: false,
  clearable: false,
  disabled: false,
  isMulti: false,
  isCheckBoxIconEnable: false,
  noOptionsMessage: "No options",
};
