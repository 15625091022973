import styled from 'styled-components/macro'
import { MENU, BREAK_ } from 'constants'

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  height: 72px;
  padding: 16px 48px;
  background: ${({ clr }) => clr?.primary};
  ${BREAK_.md_up} {
    z-index: 5; // need this to cover sidebar and footer with overlay
    width: 100%
  }
  ${BREAK_.md_dn} {
    background: #f9f9f9;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 0 16px;
  }
  display: flex;
  justify-content: center;
  > span {
    display: flex;
    flex: 1;
    border: 0px solid black;
     gap: 48px;
     ${BREAK_.md_dn} {
      gap: 24px;
    }
  }
  > span:nth-child(1) {
    justify-content: flex-start;
  }
  > span:nth-child(2) {
    justify-content: center;
  }
  > span:nth-child(3) {
    justify-content: flex-end;
  }
  transition: padding-left 0.3s ease, padding-right 0.3s ease,
    margin-top 0.3s ease, margin-left 0.3s ease, height 0.3s ease,
    width 0.3s ease;
`

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.125rem;
  gap: 8px;
  padding:0 20px; 
   color:${({ clr }) => clr?.sub_text_color};
  ${BREAK_.md_dn} {
    color:${({ clr }) => clr?.alternative_condition} ;
`