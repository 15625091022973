import Select from 'react-select'
import { PlaceHolderInputField, styles } from './Dropdown.Style'
import { Controller } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomMenuList, CustomValueContainer, DropdownIndicator } from './CustomMenulist';
import { useEffect, useRef, useState } from 'react';
import { HelperText } from 'components/FormComponent/TextField';

export const PhoneNumberDropdown = ({ searchable, placeholder, description, options=[], control, value, onChange, getPhoneId,
  getPhoneNumber, error, helperText }) => {
    const containerRef = useRef(null);
    const { i18n: { language }, } = useTranslation();
    const [isFocused, setIsFocused] = useState(false);
    const [inputValuea, setInputValuea] = useState('');

  // handling the field which should have only number allowed
  const handleNumberInput = (event) => {
    return (event.key.charCodeAt() >= 48 && event.key.charCodeAt() <= 57) || event.key === 'Backspace' ? event.key : event.preventDefault();
  }


  // function for controlling dropdown menu open/close behavior
  const onDomClick = (e) => {
    
    let menu = containerRef.current?.querySelector(".select__menu");
    if (
      !containerRef?.current?.contains(e.target) ||
      !menu ||
      !menu?.contains(e.target)
    ) {
      setIsFocused(false);
      setInputValuea("");
    }
  };


  // filter the option data
    //Custom search filter
    const customFilter = (o) => {
      if ( o.value.toLowerCase().includes(inputValuea?.toLowerCase()) ) {
        return true;
      } else {
        return false;
      }
    };

  useEffect(() => {
    document.addEventListener("mousedown", onDomClick);

    return () => {
      document.removeEventListener("mousedown", onDomClick);
    };
  }, []);

    return (
      <Box>
          <Grid sx={{  position: "relative", display: "flex", "& .react-select-container": {position: 'unset'}, width: "300px",
  maxWidth: "100%" }}>
          <Grid xs={5}>
            <Controller
              defaultValue=""
              control={control}
              name={getPhoneId}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                const filterValue = options.filter(
                  (cd) => cd.DataValue2 === value?.value
                );
                const selectedVal = filterValue?.length
                  ? {
                      value: filterValue[0]?.Id,
                      label: (
                        <div>
                          <div className="radius-4 me-2">
                            <img
                              src={filterValue[0].DataValue1}
                              style={{
                                width: "20px",
                              }}
                              alt="country flag"
                            />
                          </div>
                          <div>
                            <div
                              className="fs-14 light-font-text d-flex gap-1"
                              style={{
                                color: "rgba(0, 0, 0, 1)",
                                opacity: "1",
                              }}
                            >
                              <span>{filterValue[0]?.Id}</span>
                              <span>{filterValue[0]?.DataValue2}</span>
                            </div>
                          </div>
                        </div>
                      ),
                    }
                  : "";

                return (
                  <Select   
                  error={error}
                  className="react-select-container"
                  placeholder={<span>{placeholder}</span>}
                  isSearchable={searchable}
                  setInputValuea={setInputValuea}
                  options={options.map((cnty) => ({
                    ...cnty,
                    value: `${cnty.country_code} ${language === "da" ? cnty?.name_dk : cnty?.name_eng }`,
                    label: `${cnty.country_code} ${language === "da" ? cnty?.name_dk : cnty?.name_eng }`,
                  })).filter((f) => customFilter(f))} 
                  styles={styles} 
                  value={value}
                  components={{
                    MenuList: CustomMenuList,
                    ValueContainer: CustomValueContainer,
                    DropdownIndicator
                  }}
                  onFocus={()=>  setIsFocused(true)}
                  onMenuInputFocus={() => {
                    setIsFocused(true)
                  }}
                  description={<span className='label-regular'>{description}</span>}
                  onChange={(e)=>{onChange(e); setIsFocused(false);}}
                  {...{
                    menuIsOpen: isFocused || undefined,
                    isFocused: isFocused || undefined,
                  }}
                  /> 
                );
              }}
            />
        </Grid>
        <Grid xs={7}>
          <Controller
              defaultValue=""
              control={control}
              name={getPhoneNumber}
              rules={{ required: true }}
              render={({ field: { name, value, onChange } }) => {
                return (
                  <PlaceHolderInputField 
                    error={error}
                    label={language === "da" ? "Telefon" : "Phone"}
                    // inputProps={{ className: "paragraph2-light" }} 
                    name={name}
                    value={value}
                    onChange={onChange}
                    onKeyDown={handleNumberInput}
                    type="tel"
                    />
                );
              }}
            />
        </Grid>
      </Grid>
      {
          error && <HelperText helperText={helperText} />
        }
      </Box>
      )
}


PhoneNumberDropdown.defaultProps = {
  searchable: false,
  clearable: false,
  disabled: false,
  noOptionsMessage: "No options",
};

// (<PlaceHolderInputField
//   name={name}
//   onKeyDown={handleNumberInput}
//   type="tel"
//   />)