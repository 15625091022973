
export const FormCheckBox = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="19" height="19" rx="7.5" fill="white" stroke="#D1D1D1"/>
    </svg>
}



export const FormCheckedBox = ({fillClr}) => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="8" fill={fillClr} />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 11.9572L8.41667 14.8096C8.60249 14.9693 8.84735 15.0332 9.08333 14.9835C9.32137 14.9369 9.52821 14.7846 9.65 14.5661L15 5" fill={fillClr}/>
    <path d="M5 11.9572L8.41667 14.8096C8.60249 14.9693 8.84735 15.0332 9.08333 14.9835C9.32137 14.9369 9.52821 14.7846 9.65 14.5661L15 5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
}