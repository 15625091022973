import { Box, Divider, Drawer, IconButton, useMediaQuery } from "@mui/material";
import { Icon } from "components/Icons";
import React from "react";
import ContactForm from "./ContactForm";
import { useContactDrawer } from "./useContactDrawer";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { STATICS } from "api/api";
import { useLocation } from "react-router-dom";
import { PoweredBy } from "components/PoweredBy/PoweredBy";

// Function to remove duplicates from contactData based on unique properties
const removeDuplicates = (data) => {

  const seen = new Set();
  return data.filter(item => {
    const identifier = `${item?.Name}|${item?.AddressLine1}|${item?.AddressLine2}|${item?.Phone}|${item?.Email}`;
    return !seen.has(identifier) && seen.add(identifier);
  });
};

const ContactDrawer = () => {
  const here = useLocation().pathname;
  const atUnit = here.includes("unit");

  const { isOpen, toggle, unit } = useContactDrawer();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];
  const contactData = getFocus(_project, 'ContactProperties')?.[pin]?.[pid] ?? [];

  // Filter out duplicates
  const uniqueContactData = removeDuplicates(contactData);

  return (
    <Drawer
    hideBackdrop={true}
    anchor={isMobile ? "bottom" : "right"}
    open={isOpen}
    onClose={() => toggle()}
    PaperProps={{ sx: { scrollbarWidth: "none", "&::-webkit-scrollbar": { display: "none" }, color: "secondary.contrastText", backgroundColor: "secondary.main", height: { lg: `calc(100% - 162px - ${atUnit || isMobile ? "-90px" : "2px"})` }, top: `${isMobile ? "58px" : "70px"}`, justifyContent: "space-between", boxShadow: 'unset', paddingBottom: { xs:"16px", lg: 0 } } }}
    sx={{  "&.MuiDrawer-root": { width: 450, top: "70px", right: 0, left: 'unset', bottom: 'unset' } }}
    >
      <Box sx={{ width: isMobile ? "auto" : 450, position: "relative", pt: 4, pb: 4, px: 3, lineHeight: 1.3 }}>
        <IconButton
          onClick={toggle}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
          }}
        >
           <span className="icon-hover-effect" >
          <Icon
            icon="close"
            isFaIcon={true}
            size={"lg"}
            sx={{
              fontSize: 18,
            }}
          />
          </span>
        </IconButton>

        <Box sx={{lineHeight: 1.5}}>
          <Box sx={{ fontWeight: 700, mb: 1 }}>
            {buttonsLabel?.filter(btn => btn?.UILabelProperty === 'Kontaktinformation')[0]?.Label}
          </Box>
          {
            uniqueContactData?.map((item, i) => {
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} key={i}>
                  <Box>
                    <p>{item?.Name}</p>
                    <p>{item?.AddressLine1}</p>
                    <p>{item?.AddressLine2}</p>
                    <p>{item?.Phone}</p>
                    <p>{item?.Email}</p>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '150px' }}>
                    <img src={`${STATICS}${item?.ImageUrl}`} alt='' />
                  </Box>
                </Box>
              )
            })
          }
        </Box>
        <Divider sx={{ my: 4, borderColor: `#fff` }} />

        <ContactForm unit={unit} campaignId={'b307fc2299b0c75053081c1bde9bf622'}>
          <Box sx={{ fontWeight: 700, lineHeight: 1.4, mb: 3 }}>
            {
              buttonsLabel?.filter(
                (btn) =>
                  btn?.UILabelProperty === "SidefodformularOverskrift"
              )[0]?.Label
            }
            <br />
            <br />
            <span
              style={{
                fontSize: "0.95em",
                fontWeight: "normal",
              }}
            >
              {
                buttonsLabel?.filter(
                  (btn) =>
                    btn.UILabelProperty === "SidefodformularUnderoverskrift"
                )[0]?.Label
              }
            </span>
          </Box>
        </ContactForm>
      </Box>
      <PoweredBy padding={ isMobile ? "0 0 60px 0" : '16px 0 16px 0'} clr="#FFF"/>
    </Drawer>
  );
};

export default ContactDrawer;
