import { useFetch_Highlights } from "hooks/fetch/useFetch_Highlights";
import { useGetUnit } from "hooks/useGetUnit";
import { useAtom, getFocus, useGet } from "state/jotai";
import { active_unit, active_project } from "state/store.global";
import Unit from "./Unit.model";
import { applyfilters } from 'utilities/utility.filter'
import { getProjectData } from "state/jotai.projectDataHooks";
import { _project } from 'state/store.projects';

const useActiveUnit = () => {
  const [unit] = useAtom(active_unit);
  return new Unit(unit);
};

const useUnitById = (unitId) => {
  const unit = useGetUnit(unitId);
  return new Unit(unit);
};

const useRelatedUnits = (unitId) => {
  const unit = useGetUnit(unitId);

  const raw = getProjectData('unitsraw', 'Units')

  const area = unit?.Data?.Areal?.UnformattedValue
  const price = unit?.Data?.Pris?.UnformattedValue

  const filter = {
    interval: [
      { property: 'Pris', min: price - 2000, max: price + 2000 },
      { property: 'Areal', min: area - 15, max: area + 15 },
    ],
    multiSelect: [
      { property: 'Status', values: ['UnitStatusLedig'] }
    ]
  }

  const relatedUnits = applyfilters(raw, filter)
    .filter(i => i.UnitID !== unitId)
    .slice(-4)
    .map(i => i)
  // .map( i => new Unit(i))
// console.log(relatedUnits,"relelel")
  return relatedUnits;
};

const useUnitHighlight = (unit) => {
  // const { highlights: h } = useFetch_Highlights();
const { pin, pid } = useGet(active_project)
const highlights   = getFocus(_project, 'Highlights')?.[pin]?.[pid] ?? []

// removing duplicate data
let jsonObject = highlights.map(JSON.stringify);
let uniqueSet = new Set(jsonObject);
let uniqueArrayMenusItems = Array.from(uniqueSet).map(JSON.parse);

return uniqueArrayMenusItems?.map((hl) => {
  return {
    value   : unit.getValue(hl.Property),
    postfix : unit.getPostfix(hl.Property),
    name    : unit.getLabel(hl.Property),
    property: hl.Property,
    icon    : hl.Icons
  };
});
};

export { useActiveUnit, useUnitById, useUnitHighlight, useRelatedUnits };
